import throphy from "../../assets/throphy.png";
import supergra from "../../assets/supergra.png";
import supergraBg from "../../assets/bgsupergra.png";
import logo777 from "../../assets/logo777.png";
import bg777 from "../../assets/bg777.png";
import favbet from "../../assets/favbet.png";
import favbetBg from "../../assets/bgfavbet.png";
import favbetBg2 from "../../assets/bgfavbet2.png";
import ggbet from "../../assets/ggbet.png";
import bgggbet from "../../assets/bgggbet.png";
import bgslotscity from "../../assets/bgslotscity.png";
import slotscity from "../../assets/slotscity.png";
import bgfirst from "../../assets/bgfirst.png";
import first from "../../assets/first.png";

export const Main = () => {
  const params = new URLSearchParams(document.location.search);
  const sub1 = params.get("sub1") ?? localStorage.getItem("sub1");
  const sub2 = params.get("sub2") ?? localStorage.getItem("sub2");
  const sub3 = params.get("sub3") ?? localStorage.getItem("sub3");
  const sub4 = params.get("sub4") ?? localStorage.getItem("sub4");
  const sub5 = params.get("sub5") ?? localStorage.getItem("sub5");
  const sub6 = params.get("sub6") ?? localStorage.getItem("sub6");
  const sub7 = params.get("sub7") ?? localStorage.getItem("sub7");
  const sub8 = params.get("sub8") ?? localStorage.getItem("sub8");
  const sub9 = params.get("sub9") ?? localStorage.getItem("sub9");
  const pixel = params.get("pixel") ?? localStorage.getItem("pixel");

  if (sub1 !== null && sub1) {
    localStorage.setItem("sub1", sub1);
  }

  if (sub2 !== null && sub2) {
    localStorage.setItem("sub2", sub2);
  }

  if (sub3 !== null && sub3) {
    localStorage.setItem("sub3", sub3);
  }

  if (sub4 !== null && sub4) {
    localStorage.setItem("sub4", sub4);
  }

  if (sub5 !== null && sub5) {
    localStorage.setItem("sub5", sub5);
  }

  if (sub6 !== null && sub6) {
    localStorage.setItem("sub6", sub6);
  }

  if (sub7 !== null && sub7) {
    localStorage.setItem("sub7", sub7);
  }
  if (sub8 !== null && sub8) {
    localStorage.setItem("sub8", sub8);
  }
  if (sub9 !== null && sub9) {
    localStorage.setItem("sub9", sub9);
  }

  if (pixel !== null && pixel) {
    localStorage.setItem("pixel", pixel);
  }

  const clickHandler = (t, tgLink, offer) => {
    let e = "deep-link";
    let r = true;
    let n = {
      variables: {},
    };
    if (sub1 !== null) {
      n.variables["sub1"] = sub1;
    }
    if (sub2 !== null) {
      n.variables["sub2"] = sub2;
    }
    if (sub3 !== null) {
      n.variables["sub3"] = sub3;
    }
    if (sub4 !== null) {
      n.variables["sub4"] = sub4;
    }
    if (sub5 !== null) {
      n.variables["sub5"] = sub5;
    }
    if (sub6 !== null) {
      n.variables["sub6"] = sub6;
    }
    if (sub7 !== null) {
      n.variables["sub7"] = sub7;
    }
    if (sub8 !== null) {
      n.variables["sub8"] = sub8;
    }
    if (sub9 !== null) {
      n.variables["sub9"] = sub9;
    }
    let a = {
      context: { range: [], scope: {}, variables: n },
      messengers: [
        {
          key: "?",
          name: "telegram",
          parameter: "start",
          prefixes: ["tg://", "https://t.me/"],
        },
      ],
      delimiter: "|",
      url: "/api/i/store",
    };
    // document
    //   .getElementById("tg://resolve?start=ZGw6MTk5Mzc1&domain=sloto_ua_bot")
    //   .addEventListener("click", function (t) {
    // eslint-disable-next-line no-unused-expressions
    t.preventDefault(),
      (function (e) {
        let t = new XMLHttpRequest();
        // eslint-disable-next-line no-unused-expressions
        t.open("POST", "https://slotoua.customer.smartsender.eu/api/i/store"),
          t.setRequestHeader("Content-type", "application/json"),
          t.setRequestHeader("X-Requested-With", "XMLHttpRequest"),
          (t.onreadystatechange = function () {
            if (4 === this.readyState && 200 === this.status) {
              let t = JSON.parse(this.responseText);
              window.location.href = (function (e, t) {
                let r = a.messengers.find(function (t) {
                    return t.prefixes.find(function (t) {
                      return 0 === e.indexOf(t);
                    });
                  }),
                  n = e.split(r.key),
                  s = n[1].split("&");
                return (
                  s.forEach(function (e, n) {
                    let i = e.split("=");
                    if (r.parameter === i[0]) {
                      let e = btoa(atob(i[1]) + a.delimiter + t.id).replace(
                        /=/g,
                        ""
                      );
                      s.splice(n, 1, [r.parameter, e].join("="));
                    }
                  }),
                  n[0] + r.key + s.join("&")
                );
              })(e, t);
            }
          }),
          t.send(
            (function (e) {
              return (
                Object.keys(a.context).forEach(function (t) {
                  Object.keys(e).includes(t) || (e[t] = {});
                }),
                JSON.stringify(
                  r
                    ? ((t = e),
                      new URLSearchParams(window.location.search).forEach(
                        function (e, r) {
                          t.variables.hasOwnProperty(r) || (t.variables[r] = e);
                        }
                      ),
                      t)
                    : e
                )
              );
              var t;
            })(n)
          );
      })(tgLink);
    setTimeout(() => {
      if (offer === "super") {
        window.location.href = "/redirect?offer=super";
      } else if (offer === "favbet") {
        window.location.href = "/redirect?offer=favbet";
      } else if (offer === "777") {
        window.location.href = "/redirect?offer=777";
      } else if (offer === "ggbet") {
        window.location.href = "/redirect?offer=ggbet";
      } else if (offer === "slotscity") {
        window.location.href = "/redirect?offer=slotscity";
      } else if (offer === "first") {
        window.location.href = "/redirect?offer=first";
      }
    }, 3000);
  };

  return (
    <main className="main">
      <div className="container">
        <div className="main__wrapper">
          <img src={throphy} alt="throphy" className="main__throphy" />
          <p className="main__title">топ казино</p>
        </div>
        <div className="main__thumb">
          {/*<div className="main__item--wrapper">
            <div
              className="main__item"
              style={{
                backgroundImage: `url(${bgggbet})`,
                backgroundSize: "cover",
              }}
            >
              <img src={ggbet} alt="logotype" width={254} height={76} />
              <p className="main__item--title">забирай бонус</p>
              <ul className="main__prize--list">
                <li className="main__prize--item main__prize--white">
                  <p className="main__prize--top main__prize--textWhite">
                    12000
                  </p>
                  <p className="main__prize--bottom main__prize--textWhite">
                    гривень
                  </p>
                </li>
                <li className="main__prize--item main__prize--yellow">
                  <p className="main__prize--top main__prize--textYellow">50</p>
                  <p className="main__prize--bottom main__prize--textYellow">
                    ФРІСПІНІВ
                  </p>
                </li>
              </ul>
              <button
                type="button"
                className="main__button"
                onClick={(e) => {
                  clickHandler(
                    e,
                    "tg://resolve?start=ZGw6MjA5MDcx&domain=super_spinns_bot",
                    "ggbet"
                  );
                }}
              >
                Забрати бонус
              </button>
            </div>
          </div> */}
          {/* <div className="main__item--wrapper">
            <div
              className="main__item"
              style={{
                backgroundImage: `url(${bgfirst})`,
                backgroundSize: "cover",
              }}
            >
              <img src={first} alt="logotype" width={254} height={76} />
              <p className="main__item--title">забирай бонус</p>
              <ul className="main__prize--list">
                <li className="main__prize--item main__prize--white">
                  <p className="main__prize--top main__prize--textWhite">
                    100000
                  </p>
                  <p className="main__prize--bottom main__prize--textWhite">
                    гривень
                  </p>
                </li>
                <li className="main__prize--item main__prize--yellow">
                  <p className="main__prize--top main__prize--textYellow">
                    400
                  </p>
                  <p className="main__prize--bottom main__prize--textYellow">
                    ФРІСПІНІВ
                  </p>
                </li>
              </ul>
              <button
                type="button"
                className="main__button"
                onClick={(e) => {
                  clickHandler(
                    e,
                    "tg://resolve?start=ZGw6MjEwNjAx&domain=super_spinns_bot",
                    "first"
                  );
                }}
              >
                Забрати бонус
              </button>
            </div>
          </div> */}
          <div className="main__item--wrapper">
            <div
              className="main__item"
              style={{
                backgroundImage: `url(${bgslotscity})`,
                backgroundSize: "cover",
              }}
            >
              <img src={slotscity} alt="logotype" width={254} height={76} />
              {/* <p className="main__item--title">забирай бонус</p> */}
              <ul className="main__prize--list">
                <li className="main__prize--item main__prize--white">
                  <p className="main__prize--top main__prize--textWhite">100</p>
                  <p className="main__prize--bottom main__prize--textWhite">
                    ФРІСПІНІВ
                  </p>
                </li>
                <li className="main__prize--item main__prize--yellow">
                  <p className="main__prize--top main__prize--textYellow">
                    БЕЗ
                  </p>
                  <p className="main__prize--bottom main__prize--textYellow">
                    відіграшу
                  </p>
                </li>
              </ul>
              <div
                className="main__fade"
                style={{
                  background:
                    "radial-gradient(50% 15689.8% at 50% 50%, #F1392E 0%, rgba(241, 57, 46, 0) 100%)",
                }}
              >
                Промокод - CLUBWIN
              </div>
              <button
                type="button"
                className="main__button"
                onClick={(e) => {
                  clickHandler(
                    e,
                    "tg://resolve?start=ZGw6MjEwNTY1&domain=super_spinns_bot",
                    "slotscity"
                  );
                }}
              >
                Забрати бонус
              </button>
            </div>
          </div>
          <div className="main__item--wrapper">
            <div
              className="main__item"
              style={{
                backgroundImage: `url(${supergraBg})`,
                backgroundSize: "cover",
              }}
            >
              <img src={supergra} alt="logotype" width={254} height={76} />
              <p className="main__item--title">забирай бонус</p>
              <ul className="main__prize--list">
                <li className="main__prize--item main__prize--white">
                  <p className="main__prize--top main__prize--textWhite">100</p>
                  <p className="main__prize--bottom main__prize--textWhite">
                    ФРІСПІНІВ
                  </p>
                </li>
                <li className="main__prize--item main__prize--yellow">
                  <p className="main__prize--top main__prize--textYellow">
                    БЕЗ
                  </p>
                  <p className="main__prize--bottom main__prize--textYellow">
                    відіграшу
                  </p>
                </li>
              </ul>
              <button
                type="button"
                className="main__button"
                onClick={(e) => {
                  clickHandler(
                    e,
                    "tg://resolve?start=ZGw6MjA2Mzcx&domain=super_spinns_bot",
                    "super"
                  );
                }}
              >
                Забрати бонус
              </button>
            </div>
          </div>
          {/* <div className="main__item--wrapper">
            <div
              className="main__item"
              style={{
                backgroundImage: `url(${favbetBg})`,
                backgroundSize: "cover",
              }}
            >
              <img src={favbet} alt="logotype" width={254} height={76} />
              <p className="main__item--title">забирай бонус</p>
              <ul className="main__prize--list">
                <li className="main__prize--item main__prize--white">
                  <p className="main__prize--top main__prize--textWhite">100</p>
                  <p className="main__prize--bottom main__prize--textWhite">
                    ФРІСПІНІВ
                  </p>
                </li>
                <li className="main__prize--item main__prize--yellow">
                  <p className="main__prize--top main__prize--textYellow">
                    БЕЗ
                  </p>
                  <p className="main__prize--bottom main__prize--textYellow">
                    відіграшу
                  </p>
                </li>
              </ul>
              <button
                type="button"
                className="main__button"
                onClick={(e) => {
                  clickHandler(
                    e,
                    "tg://resolve?start=ZGw6MjA2MzY1&domain=super_spinns_bot",
                    "favbet"
                  );
                }}
              >
                Забрати бонус
              </button>
            </div>
          </div> */}
          {/* <div className="main__item--wrapper">
            <div
              className="main__item"
              style={{
                backgroundImage: `url(${favbetBg2})`,
                backgroundSize: "cover",
              }}
            >
              <img src={favbet} alt="logotype" width={254} height={76} /> */}
          {/* <p className="main__item--title">забирай бонус</p> */}
          {/* <ul className="main__prize--list">
                <li className="main__prize--item main__prize--white">
                  <p className="main__prize--top main__prize--textWhite">25</p>
                  <p className="main__prize--bottom main__prize--textWhite">
                    ФРІСПІНІВ
                  </p>
                </li>
                <li className="main__prize--item main__prize--yellow">
                  <p className="main__prize--top main__prize--textYellow">
                    БЕЗ
                  </p>
                  <p className="main__prize--bottom main__prize--textYellow">
                    відіграшу
                  </p>
                </li>
              </ul>
              <div className="main__fade">Промокод - fav25fs</div>
              <button
                type="button"
                className="main__button"
                onClick={(e) => {
                  clickHandler(
                    e,
                    "tg://resolve?start=ZGw6MjA4NDA4&domain=super_spinns_bot",
                    "favbet"
                  );
                }}
              >
                Забрати бонус
              </button>
            </div>
          </div> */}
          {/* <div className="main__item--wrapper">
            <div
              className="main__item"
              style={{
                backgroundImage: `url(${bg777})`,
                backgroundSize: "cover",
              }}
            >
              <img src={logo777} alt="logotype" width={254} height={76} />
              <p className="main__item--title">забирай бонус</p>
              <ul className="main__prize--list">
                <li className="main__prize--item main__prize--white">
                  <p className="main__prize--top main__prize--textWhite">200</p>
                  <p className="main__prize--bottom main__prize--textWhite">
                    ГРН
                  </p>
                </li>
                <li className="main__prize--item main__prize--yellow">
                  <p className="main__prize--top main__prize--textYellow">
                    БЕЗ
                  </p>
                  <p className="main__prize--bottom main__prize--textYellow">
                    ДЕПОЗИТУ
                  </p>
                </li>
              </ul>
              <button
                type="button"
                className="main__button"
                onClick={(e) => {
                  clickHandler(
                    e,
                    "tg://resolve?start=ZGw6MjA2MzY4&domain=super_spinns_bot",
                    "777"
                  );
                }}
              >
                Забрати бонус
              </button>
            </div>
          </div> */}
        </div>
      </div>
    </main>
  );
};
