import logo from "../../assets/logo.png";
import { useEffect } from "react";

export const Header = () => {
  useEffect(() => {
    let pixel_id = 308334282290683;
    // Inject Facebook pixel script
    const element = document.getElementById("pixelTagId");
    if (element) {
      /* global fbq */ // Declare fbq as a global variable
      // eslint-disable-next-line
      !(function (f, b, e, v, n, t, s) {
        if (f.fbq) return;
        n = f.fbq = function () {
          n.callMethod
            ? n.callMethod.apply(n, arguments)
            : n.queue.push(arguments);
        };
        if (!f._fbq) f._fbq = n;
        n.push = n;
        n.loaded = !0;
        n.version = "2.0";
        n.queue = [];
        t = b.createElement(e);
        t.async = !0;
        t.src = v;
        s = b.getElementsByTagName(e)[0];
        s.parentNode.insertBefore(t, s);
      })(
        window,
        document,
        "script",
        "https://connect.facebook.net/en_US/fbevents.js"
      );
      fbq("init", pixel_id);
      fbq("track", "Lead");
      element.innerHTML = `<img alt='test' src=https://www.facebook.com/tr?id=${pixel_id}&ev=PageView&noscript=1 style=\"display:none\" width=\"1px\" height=\"1px\">`;
    }
  }, []);
  return (
    <header className="header">
      <div className="container">
        <img src={logo} alt="logotype" className="header__logo" />
      </div>
    </header>
  );
};
