import { useEffect } from "react";

export const Redirect = () => {
  const sub1 = localStorage.getItem("sub1");
  const sub2 = localStorage.getItem("sub2");
  const sub3 = localStorage.getItem("sub3");
  const sub4 = localStorage.getItem("sub4");
  const sub5 = localStorage.getItem("sub5");
  const sub6 = localStorage.getItem("sub6");
  const sub7 = localStorage.getItem("sub7");
  const sub8 = localStorage.getItem("sub8");
  const sub9 = localStorage.getItem("sub9");

  const params = new URLSearchParams(document.location.search);
  const offer = params.get("offer");
  function onWebsiteFavBtnClick() {
    window.location.href =
      `https://tundo.top/4nczmBxm?a=b` +
      (sub1 !== null ? `&sub1=${sub1}` : "") +
      (sub2 !== null ? `&sub2=${sub2}` : "") +
      (sub3 !== null ? `&sub3=${sub3}` : "") +
      (sub4 !== null ? `&sub4=${sub4}` : "") +
      (sub5 !== null ? `&sub5=${sub5}` : "") +
      (sub6 !== null ? `&sub6=${sub6}` : "") +
      (sub7 !== null ? `&sub7=${sub7}` : "") +
      (sub8 !== null ? `&sub8=${sub8}` : "") +
      (sub9 !== null ? `&sub9=${sub9}` : "");
  }

  function onWebsiteSuperBtnClick() {
    window.location.href =
      `https://tundo.top/FJJW2bw9?a=b` +
      (sub1 !== null ? `&sub1=${sub1}` : "") +
      (sub2 !== null ? `&sub2=${sub2}` : "") +
      (sub3 !== null ? `&sub3=${sub3}` : "") +
      (sub4 !== null ? `&sub4=${sub4}` : "") +
      (sub5 !== null ? `&sub5=${sub5}` : "") +
      (sub6 !== null ? `&sub6=${sub6}` : "") +
      (sub7 !== null ? `&sub7=${sub7}` : "") +
      (sub8 !== null ? `&sub8=${sub8}` : "") +
      (sub9 !== null ? `&sub9=${sub9}` : "");
  }

  function onWebsite777BtnClick() {
    window.location.href =
      `https://tundo.top/v5cBZ38L?a=b` +
      (sub1 !== null ? `&sub1=${sub1}` : "") +
      (sub2 !== null ? `&sub2=${sub2}` : "") +
      (sub3 !== null ? `&sub3=${sub3}` : "") +
      (sub4 !== null ? `&sub4=${sub4}` : "") +
      (sub5 !== null ? `&sub5=${sub5}` : "") +
      (sub6 !== null ? `&sub6=${sub6}` : "") +
      (sub7 !== null ? `&sub7=${sub7}` : "") +
      (sub8 !== null ? `&sub8=${sub8}` : "") +
      (sub9 !== null ? `&sub9=${sub9}` : "");
  }

  function onWebsiteGGbetBtnClick() {
    window.location.href =
      `https://tundo.top/mDVTNZyB?a=b` +
      (sub1 !== null ? `&sub1=${sub1}` : "") +
      (sub2 !== null ? `&sub2=${sub2}` : "") +
      (sub3 !== null ? `&sub3=${sub3}` : "") +
      (sub4 !== null ? `&sub4=${sub4}` : "") +
      (sub5 !== null ? `&sub5=${sub5}` : "") +
      (sub6 !== null ? `&sub6=${sub6}` : "") +
      (sub7 !== null ? `&sub7=${sub7}` : "") +
      (sub8 !== null ? `&sub8=${sub8}` : "") +
      (sub9 !== null ? `&sub9=${sub9}` : "");
  }

  function onWebsiteSlotscityBtnClick() {
    window.location.href =
      `https://tundo.top/4R4hhrHk?a=b` +
      (sub1 !== null ? `&sub1=${sub1}` : "") +
      (sub2 !== null ? `&sub2=${sub2}` : "") +
      (sub3 !== null ? `&sub3=${sub3}` : "") +
      (sub4 !== null ? `&sub4=${sub4}` : "") +
      (sub5 !== null ? `&sub5=${sub5}` : "") +
      (sub6 !== null ? `&sub6=${sub6}` : "") +
      (sub7 !== null ? `&sub7=${sub7}` : "") +
      (sub8 !== null ? `&sub8=${sub8}` : "") +
      (sub9 !== null ? `&sub9=${sub9}` : "");
  }

  function onWebsiteFirstBtnClick() {
    window.location.href =
      `https://tundo.top/9v8TS1Vb?a=b` +
      (sub1 !== null ? `&sub1=${sub1}` : "") +
      (sub2 !== null ? `&sub2=${sub2}` : "") +
      (sub3 !== null ? `&sub3=${sub3}` : "") +
      (sub4 !== null ? `&sub4=${sub4}` : "") +
      (sub5 !== null ? `&sub5=${sub5}` : "") +
      (sub6 !== null ? `&sub6=${sub6}` : "") +
      (sub7 !== null ? `&sub7=${sub7}` : "") +
      (sub8 !== null ? `&sub8=${sub8}` : "") +
      (sub9 !== null ? `&sub9=${sub9}` : "");
  }

  useEffect(() => {
    let pixelId = localStorage.getItem("pixel");

    if (offer === "super") {
      setTimeout(() => {
        onWebsiteSuperBtnClick();
      }, 800);
    } else if (offer === "favbet") {
      setTimeout(() => {
        onWebsiteFavBtnClick();
      }, 800);
    } else if (offer === "777") {
      setTimeout(() => {
        onWebsite777BtnClick();
      }, 800);
    } else if (offer === "ggbet") {
      setTimeout(() => {
        onWebsiteGGbetBtnClick();
      }, 800);
    } else if (offer === "slotscity") {
      setTimeout(() => {
        onWebsiteSlotscityBtnClick();
      }, 800);
    } else if (offer === "first") {
      setTimeout(() => {
        onWebsiteFirstBtnClick();
      }, 800);
    }

    // Inject Facebook pixel script
    const el = document.getElementById("pixelTag2Id");
    if (el) {
      /* global fbq */ // Declare fbq as a global variable
      // eslint-disable-next-line
      !(function (f, b, e, v, n, t, s) {
        if (f.fbq) return;
        n = f.fbq = function () {
          n.callMethod
            ? n.callMethod.apply(n, arguments)
            : n.queue.push(arguments);
        };
        if (!f._fbq) f._fbq = n;
        n.push = n;
        n.loaded = !0;
        n.version = "2.0";
        n.queue = [];
        t = b.createElement(e);
        t.async = !0;
        t.src = v;
        s = b.getElementsByTagName(e)[0];
        s.parentNode.insertBefore(t, s);
      })(
        window,
        document,
        "script",
        "https://connect.facebook.net/en_US/fbevents.js"
      );
      fbq("init", pixelId);
      fbq("track", "Purchase");
      el.innerHTML = `<img alt='test' src=https://www.facebook.com/tr?id=${pixelId}&ev=PageView&noscript=1 style=\"display:none\" width=\"1px\" height=\"1px\">`;
    }
  }, []);
  return <div className="loader"></div>;
};
